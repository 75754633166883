<template>
  <div class="content-container flex-row pb-0 pt-20">
    <TopLevelLeftNav />

    <div class="page">
      <div class="subpage-banner"></div>
      <div class="subpage-content">
        <section>
          <div class="content-band">
            <div class="pagehead-title">
              <h1>Layout &amp; Spacing</h1>
              <!-- <div class="pagehead-link">
                <a href=""
                  ><img src="/html-components/images/svg/link.svg" alt=""
                /></a>
              </div> -->
            </div>
            <p>
              Layout and spacing in a website or application is almost of equal
              importance to the content. Appropriate spacing (whitespace)
              between elements lessens overwhelm, helping the user focus. When
              applied consistently, layout and spacing provide a visual map
              between the relationships of objects.
            </p>
          </div>
        </section>

        <div class="divider max-w1200"></div>

        <div class="flex-sidebar flex-wrap">
          <div class="container max-w1200">
            <section>
              <div class="content-band" id="grid">
                <h2>Grid System (or lack thereof)</h2>
                <p>
                  Previous incarnations of spacing rules attempted to govern
                  spacing with a grid approach. A grid excels at aligning
                  content columns but not rows, which often doesn't align with
                  the needs of most sites and applications. As users scroll,
                  they tend to consider each band of content in a left to right
                  format. With each line, the length of left to right scanning
                  ability decreases. Due to this, vertical spacing between bands
                  of content becomes of equal, if not more, important than
                  horizontal spacing between objects. To achieve proper spacing,
                  we now use CSS Flexible Box Layout, commonly known as Flexbox,
                  a CSS 3 web layout model.
                </p>
              </div>
            </section>

            <div class="divider"></div>

            <section id="Breakpoints">
              <div class="content-band" id="breakpoints">
                <h2>Breakpoints</h2>
                <p>
                  The responsive behavior of the Ohio Design System's layouts
                  and components will incorporate three primary breakpoint
                  ranges.
                </p>
                <ul class="bullet-red max-w800">
                  <div class="white mb-10 p-10">
                    <li>Desktop 992 px - 1440 px</li>
                  </div>
                  <div class="white mb-10 p-10">
                    <li>Tablets 768 px - 991 px</li>
                  </div>
                  <div class="white p-10"><li>Mobile 365 px</li></div>
                </ul>
              </div>
            </section>

            <div class="content-band">
              <div class="max-w800 p-30 gray-250">
                <div class="font-red semibold">
                  Avoid the Fold
                </div>
                <p>
                  The concept of a 'fold' in web or app design is used to
                  reference the information on a page that can be seen without
                  scrolling. While more practical in the past when viewports
                  were fairly similar, due to the wide variety of devices and
                  screens, the concept of the fold is quickly disappearing.
                </p>
                <p>
                  There is no denying that prioritizing important
                  actions/content to the top of the page is a good idea.
                  However, rather than 'cramming' as much content at the top of
                  a page as possible, focus on fulfilling the primary purpose of
                  the first view, to reassure the user they have landed in the
                  right spot.
                </p>
                <p>
                  If your site or application has a great number of actions
                  relieve some of the pressure on page content by exploring more
                  discoverable navigation options (a mega-menu) that will allow
                  a user to quickly dig deeper into your site to achieve their
                  objective.
                </p>
              </div>
            </div>

            <section id="Layout">
              <div class="content-band" id="layout">
                <h2>Layout</h2>
                <p>
                  The basic structure of Ohio Design System pages are sections,
                  content bands, and layout regions. These structures tie to
                  semantic web elements and are critical for users who rely on
                  screen readers to navigate the page.
                </p>
                <ul class="bullet-red">
                  <li>
                    The 'section' element defines a section in a document.
                    According to W3C's HTML documentation: "A section is a
                    thematic grouping of content, typically with a heading."
                  </li>
                  <li>
                    The content-band is a parent container that uses
                    flex-box/css grid to arrange items into a "layout region".
                  </li>
                  <li>
                    Layout regions refer to areas that contain components and
                    blocks. Layout regions consist of flex-box items that
                    respond to the width of the content-band.
                  </li>
                  <li>
                    Components and blocks are self-contained structured blocks
                    of content and interactions. Designed in a modular manner,
                    components and blocks have responsive behavior built in.
                  </li>
                </ul>
              </div>
            </section>

            <div class="divider"></div>

            <section id="Layout-region">
              <div class="content-band" id="layout">
                <h2>Layout Region</h2>
                <p class="mb-30">
                  'Layout region' refers to flex-item content areas that contain
                  components and content. Layout regions consist of flex-box
                  items that respond to the width of the content-band.
                </p>

                <div class="max-w800 mt-40">
                  <img
                    class="width-full"
                    src="../../assets/images/SVG/layout-img.svg"
                    alt=""
                  />
                </div>

                <div class="inline-flex mt-10">
                  <div class="plr-10 flex align-center">
                    <div
                      class="white mr-10 border-red-700 circle"
                      style="width:10px; height: 10px;"
                    ></div>
                    Section
                  </div>
                  <div class="plr-10 flex align-center">
                    <div
                      class="gray-400 mr-10 circle"
                      style="width:10px; height: 10px;"
                    ></div>
                    Content band
                  </div>
                  <div class="plr-10 flex align-center">
                    <div
                      class="red-700 mr-10 circle"
                      style="width:10px; height: 10px;"
                    ></div>
                    Layout-region containers
                  </div>
                </div>
              </div>
            </section>

            <section id="Layout-region-patterns">
              <div class="content-band" id="layout-region-patterns">
                <h3 class="mb-20">Layout Region Patterns</h3>

                <div class="container flex justify-center ptb-20 max-w800 red-100 min-h200">
                  <div class="container max-w300 flex-grid-example gap-5 ">
                    <div class="box basis-30"></div>
                    <div class="box basis-30"></div>
                    <div class="box basis-30"></div>
                    <div class="box basis-30 red-400"></div>
                    <div class="box basis-30"></div>
                    <div class="box basis-30"></div>
                  </div>
                </div>
                <div class="container flex white max-w800">
                  <div class="p-20 plr-30">
                    <h5 class="semibold">Flex grid</h5>
                    <div>class name .flex-grid</div>
                  </div>
                  <div class="plr-30 flex align-center">
                    The ‘flex-grid’ class will arrange flex items into a grid
                    pattern
                  </div>
                </div>
              </div>
            </section>

            <section>
              <div class="content-band">
                <div
                  class="container flex justify-center ptb-20 max-w800 red-100 min-h200"
                >
                  <div class="container max-w300 width-full flex gap-5">
                    <div class="box basis-30 red-400"></div>
                    <div class="box basis-30"></div>
                    <div class="box basis-30"></div>
                  </div>
                </div>

                <div class="container flex white max-w800">
                  <div class=" p-20 plr-30">
                    <h5 class="semibold">Even columns</h5>
                    <div>class name .even-cols</div>
                  </div>
                  <div class="plr-30 flex align-center">
                    The 'even-cols' will arrange flex items into equal columns
                  </div>
                </div>
              </div>
            </section>

            <section>
              <div class="content-band">
                <div
                  class="container flex justify-center ptb-20 max-w800 red-100 min-h200"
                >
                  <div class="container max-w300 width-full flex gap-5">
                    <div class="box red-400 basis-30"></div>
                    <div class="box basis-70"></div>
                  </div>
                </div>

                <div class="container flex white max-w800">
                  <div class=" p-20 plr-30">
                    <h5 class="semibold">Side bar</h5>
                    <div>class name .side-bar</div>
                  </div>
                  <div class="plr-30 flex align-center">
                    The ‘side-bar’ class will arrange two flex-items into 1/4 -
                    3/4 layout
                  </div>
                </div>
              </div>
            </section>

            <section id="Spacing-increments">
              <div class="content-band" id="spacing-increments">
                <h2>Spacing Increments</h2>
                <p>
                  Ohio Design System spacing is based on increments of 5px ( 5px
                  is also the smallest increment of spacing ) Exceptions exist
                  for centering of text or icons within an object, but in
                  general, these sorts of centering and positioning are left to
                  flex-box.
                </p>
                <p>
                  The maximum increment of vertical space between bands of
                  content is 60px with 30px bottom padding and 30px top padding.
                </p>
                <p>
                  The exception is for items of focus, for example, indent and
                  feature. The full context of spatial relationships could never
                  be outlined at a high-level, however spacing is specified
                  throughout the design patterns in the Design System. Below are
                  a few examples and key indicators:
                </p>
                <ul class="bullet-red">
                  <li>
                    Closely related = 10px to 15px (form labels to text input
                    fields)
                  </li>
                  <li>
                    Related = 15px (minimum) up to 20px (items in a list)
                  </li>
                  <li>
                    Grouped by function/feature = 20px (space between form
                    inputs)
                  </li>
                  <li>
                    Unrelated = 20px (minimum) up to 30px (vertical and
                    horizontal space between cards)
                  </li>
                  <li>
                    Purposeful Separation = 60px (area between sections /
                    content bands)
                  </li>
                </ul>

                <div class="max-w800 p-30 mt-60 gray-250">
                  <div class="font-red semibold">
                    Consistent Spacing
                  </div>
                  <p>
                    It is important that spacing elements remain at fixed
                    intervals and are not customized. Items spaced at fixed
                    intervals will be expected by the user, thus providing a
                    smoother 'ride' through the content. If the space used to
                    represent these relationships varies, the experience is much
                    like riding down a bumpy road.
                  </p>
                </div>
              </div>
            </section>

            <section id="Interactions-spacing">
              <div class="content-band" id="interactions-spacing">
                <h2>Interactions Spacing</h2>
                <h4 class="mt-30 font-red">Mobile</h4>
                <p>
                  When in a mobile setting all interactions (buttons, forms,
                  navigation items, dropdown select items) must have a minimum
                  tap clearance of 44px (vertical and horizontal). This is
                  important so that all users have adequate 'tap' space to
                  interact with the element.
                </p>
                <ul class="bullet-red mb-30">
                  <li>
                    For mobile navigation menus the preference is that the tap
                    space for each item is 44px tall with each item right
                    against the other.
                  </li>
                </ul>
                <h4 class="mt-30 font-red">Non-Mobile</h4>
                <p>Desktop interactions should be 1 of 2 target sizes:</p>
                <ul class="bullet-red">
                  <li>
                    44px tall for primary actions/calls-to actions.
                  </li>
                  <li>
                    34px tall for support/actions
                  </li>
                </ul>
              </div>
            </section>

            <div class="divider"></div>

            <section id="Layering">
              <div class="content-band" id="layering">
                <h2>Layering</h2>
                <p>
                  In general the State of Ohio design system does not make wide
                  use of stacking objects. Many users will struggle to navigate
                  the hierarchy of a site without layering, adding elevation as
                  a concern will add complexity to the visual hierarchy. In
                  addition overlapping elements can cause accessibility issues
                  by forcing the frequent change of focus in an unexpected
                  fashion. However layering is appropriate in certain
                  circumstances.
                </p>
                <ul class="bullet-red">
                  <li>
                    One appropriate usage of layering is to provide a user with
                    focus for a highly important task. An example of this would
                    be a modal asking a critical piece of information before the
                    user moves on.
                  </li>
                  <li>
                    Another appropriate usage of layering is to provide the user
                    additional information without losing context for the user.
                  </li>
                </ul>
              </div>
            </section>
          </div>

          <StickyTOC />
        </div>
      </div>

      <SubFooter />
    </div>
  </div>
</template>

<script>
import TopLevelLeftNav from "../../components/TopLevelLeftNav";
import SubFooter from "../../components/SubFooter";
import StickyTOC from "../StickyTOC.vue";
export default {
  name: "LayoutSpacing",
  components: {
    TopLevelLeftNav,
    StickyTOC,
    SubFooter,
  },
};
</script>

<style></style>
