<template>
  <div class="content-container flex-row pb-0 pt-20">
    <TopLevelLeftNav />

    <div class="page">
      <div class="subpage-content">
        <section>
          <div class="content-band">
            <div class="pagehead-title">
              <h1>Layout Regions</h1>
              <div class="pagehead-link">
                <a href=""
                  ><img src="/html-components/images/svg/link.svg" alt=""
                /></a>
              </div>
            </div>
            <p>
              Layout regions are areas of the layout that contain components and
              content. Layout-region patterns utilize Flexbox and CSS Grid.
            </p>
          </div>
        </section>

        <div class="divider w1200"></div>

        <div class="flex-sidebar flex-wrap">
          <div class="container w1000">
            <section>
              <div class="content-band">
                <h2>General Guidelines</h2>
                <ul class="bullet-red">
                  <li>
                    Content-bands should not be nested within outher
                    content-bands.
                  </li>
                  <li>
                    Layout regions are best suited for related content items.
                  </li>
                </ul>
              </div>
            </section>

            <div class="divider"></div>

            <section id="Layout-region">
              <div class="content-band w800">
                <h2>Layout Region</h2>
                <p class="mb-30">
                  'Layout region' refers to flex-item content areas that contain
                  components and blocks. Layout regions consist of flex-box
                  items that respond to the width of the content-band.
                </p>

                <div class="container line-height-none white p-20 ">
                  <img
                    src="../../assets/images/SVG/layout-region-example.svg"
                    alt=""
                  />
                </div>

                <div class="container p-30 pt-10 white code font-15">
                  <span class="code-blue">&#x3C;section&#x3E;</span><br />
                  <span class="code-blue pl-20">&#x3C;div</span>
                  <span class="code-green">class=</span
                  ><span class="code-red">&#x22;content-band flex&#x22;</span
                  ><span class="code-blue">&#x3E;</span><br />
                  <span class="code-blue pl-40">&#x3C;div</span>
                  <span class="code-green">class=</span
                  ><span class="code-red">&#x22;container basis-70&#x22;</span
                  ><span class="code-blue">&#x3E;&#x3C;/div&#x3E;</span><br />

                  <span class="code-blue pl-40">&#x3C;div</span>
                  <span class="code-green">class=</span
                  ><span class="code-red"
                    >&#x22;container basis-30 flex flex-col&#x22;</span
                  ><span class="code-blue">&#x3E;</span><br />

                  <span class="code-blue pl-60"
                    >&#x3C;div&#x3E; &#x3C;/div&#x3E;</span
                  ><br />
                  <span class="code-blue pl-60"
                    >&#x3C;div&#x3E; &#x3C;/div&#x3E;</span
                  ><br />

                  <span class="code-blue pl-40">&#x3C;/div&#x3E;</span><br />
                  <span class="code-blue pl-20">&#x3C;/div&#x3E;</span><br />
                  <span class="code-blue">&#x3C;/section&#x3E;</span>
                </div>
              </div>
            </section>

            <section id="Layout-region-patterns">
              <div class="content-band">
                <h3 class="mb-20">Layout Region Patterns</h3>

                <div
                  class="container flex justify-center ptb-20 w800 red-100 min-h200"
                >
                  <div class="container w300 flex-grid-example gap-5 ">
                    <div class="box basis-30"></div>
                    <div class="box basis-30"></div>
                    <div class="box basis-30"></div>
                    <div class="box basis-30 red-400"></div>
                    <div class="box basis-30"></div>
                    <div class="box basis-30"></div>
                  </div>
                </div>
                <div class="container flex white w800">
                  <div class="p-20 plr-30">
                    <h5 class="semibold">Flex grid</h5>
                    <div>class name .flex-grid</div>
                  </div>
                  <div class="plr-30 flex align-center">
                    The ‘flex-grid’ class will arrange flex items into a grid
                    pattern
                  </div>
                </div>
              </div>
            </section>

            <section>
              <div class="content-band">
                <div
                  class="container flex justify-center ptb-20 w800 red-100 min-h200"
                >
                  <div class="container w300 width-full flex gap-5">
                    <div class="box basis-30 red-400"></div>
                    <div class="box basis-30"></div>
                    <div class="box basis-30"></div>
                  </div>
                </div>

                <div class="container flex white w800">
                  <div class=" p-20 plr-30">
                    <h5 class="semibold">Even columns</h5>
                    <div>class name .even-cols</div>
                  </div>
                  <div class="plr-30 flex align-center">
                    The 'even-cols' will arrange flex items into equal columns
                  </div>
                </div>
              </div>
            </section>

            <section>
              <div class="content-band">
                <div
                  class="container flex justify-center ptb-20 w800 red-100 min-h200"
                >
                  <div class="container w300 width-full flex gap-5">
                    <div class="box red-400 basis-30"></div>
                    <div class="box basis-70"></div>
                  </div>
                </div>

                <div class="container flex white w800">
                  <div class=" p-20 plr-30">
                    <h5 class="semibold">Side bar</h5>
                    <div>class name .side-bar</div>
                  </div>
                  <div class="plr-30 flex align-center">
                    The ‘side-bar’ class will arrange two flex-items into 1/4 -
                    3/4 layout
                  </div>
                </div>
              </div>
            </section>
          </div>

          <StickyTOC />
        </div>
      </div>

      <SubFooter />
    </div>
  </div>
</template>

<script>
import TopLevelLeftNav from "../../components/TopLevelLeftNav";
import SubFooter from "../../components/SubFooter";
import StickyTOC from "../StickyTOC.vue";
export default {
  name: "LayoutRegions",
  components: {
    TopLevelLeftNav,
    StickyTOC,
    SubFooter
  },
};
</script>
