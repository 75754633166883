<template>
  <div class="content-container flex-row pb-0 pt-20">
    <TopLevelLeftNav />

    <div class="page">
      <div class="subpage-content">
        <section>
          <div class="content-band">
            <div class="pagehead-title">
              <h1>Flexbox Utilities</h1>
              <div class="pagehead-link">
                <a href=""
                  ><img src="/html-components/images/svg/link.svg" alt=""
                /></a>
              </div>
            </div>
            <p>
              Flex utilities can be used to apply flexbox behaviors to elements
              by using utility classes.
            </p>
          </div>
        </section>

        <div class="divider w1200"></div>

        <div class="flex-sidebar flex-wrap">
          <div class="container w1000">
            <section>
              <div class="content-band">
                <h2>Flex containers</h2>
                <p class="mb-30">
                  Use these classes to make an element lay out its content using
                  the flexbox model. Each direct child of the flex container
                  will become a flex item.
                </p>

                <h3 class="mb-20">CSS</h3>
                <div
                  class="container p-30 gray-250 w800 hover-white hover-shadow"
                >
                  <p class="mt-0">.flex { display: flex; }</p>
                  <p class="m-0">.inline-flex { display: inline-flex; }</p>
                </div>

                <div class="mt-30">
                  <h3 class="mb-20">Classes</h3>

                  <div
                    class="flex flex-col border-400 w800 hover-white hover-shadow"
                  >
                    <div class="flex">
                      <div class="container plr-30 ptb-20 basis-25 gray-250">
                        Class
                      </div>
                      <div class="container plr-30 ptb-20 basis-75 gray-250">
                        Description
                      </div>
                    </div>

                    <div class="flex border-bottom">
                      <div
                        class="container plr-30 ptb-20 basis-25 flex align-center border-right min-w100"
                      >
                        .flex
                      </div>
                      <div class="container plr-30 ptb-20 basis-75">
                        The element behaves like a block and lays out its
                        content using the flexbox model.
                      </div>
                    </div>

                    <div class="flex">
                      <div
                        class="container plr-30 ptb-20 basis-25 flex align-center border-right min-w100"
                      >
                        .inline-flex
                      </div>
                      <div class="container plr-30 ptb-20 basis-75">
                        The element behaves like an inline element and lays out
                        its content using the flexbox model.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section>
              <div class="content-band">
                <h2>Flex direction</h2>
                <p>
                  Use these classes to define the orientation of the main axis
                  (row or column). By default, flex items will display in a row.
                  Use .flex-column to change the direction of the main axis to
                  vertical.
                </p>

                <h3 class="mb-20">CSS</h3>
                <div
                  class="container p-30 gray-250 w800 hover-white hover-shadow"
                >
                  <p class="mt-0">.flex-row { flex-direction: row; }</p>
                  <p>.flex-row-reverse { flex-direction: row-reverse; }</p>
                  <p>.flex-col { flex-direction: column }</p>
                  <p>.flex-col-reverse { flex-direction: column-reverse; }</p>
                </div>

                <div class="mt-30">
                  <h3 class="mb-20">Classes</h3>

                  <div
                    class="flex flex-col border-400 w800 hover-white hover-shadow"
                  >
                    <div class="flex">
                      <div class="container plr-30 ptb-20 basis-25 gray-250">
                        Class
                      </div>
                      <div class="container plr-30 ptb-20 basis-75 gray-250">
                        Description
                      </div>
                    </div>

                    <div class="flex border-bottom">
                      <div
                        class="container plr-30 ptb-20 basis-25 flex align-center border-right min-w100"
                      >
                        .flex-row
                      </div>
                      <div
                        class="container plr-30 ptb-20 basis-75 flex align-center"
                      >
                        The main axis runs left to right (default).
                      </div>
                    </div>

                    <div class="flex border-bottom">
                      <div
                        class="container plr-30 ptb-20 basis-25 flex align-center border-right min-w100"
                      >
                        .flex-row-reverse
                      </div>
                      <div
                        class="container plr-30 ptb-20 basis-75 flex align-center"
                      >
                        The main axis runs right to left.
                      </div>
                    </div>

                    <div class="flex border-bottom">
                      <div
                        class="container plr-30 ptb-20 basis-25 flex align-center border-right min-w100"
                      >
                        .flex-col
                      </div>
                      <div
                        class="container plr-30 ptb-20 basis-75 flex align-center"
                      >
                        The main axis runs top to bottom.
                      </div>
                    </div>

                    <div class="flex">
                      <div
                        class="container plr-30 ptb-20 basis-25 flex align-center border-right min-w100"
                      >
                        .flex-col-reverse
                      </div>
                      <div
                        class="container plr-30 ptb-20 basis-75 flex align-center"
                      >
                        The main axis runs bottom to top.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section>
              <div class="content-band">
                <h2>Justify content</h2>
                <p>
                  Use these classes to distribute space between and around flex
                  items along the main axis of the container.
                </p>

                <h3 class="mb-20">CSS</h3>
                <div
                  class="container p-30 gray-250 w800 hover-white hover-shadow"
                >
                  <p class="mt-0">
                    .justify-start { justify-content: flex-start }
                  </p>
                  <p>.justify-center { justify-content: center }</p>
                  <p>.justify-end { justify-content: flex-end }</p>
                  <p>.justify-between { justify-content: space-between }</p>
                  <p>.justify-around { justify-content: space-around }</p>
                </div>

                <div class="mt-30">
                  <h3 class="mb-20">Classes</h3>

                  <div
                    class="flex flex-col border-400 w800 hover-white hover-shadow"
                  >
                    <div class="flex">
                      <div class="container plr-30 ptb-20 basis-25 gray-250">
                        Class
                      </div>
                      <div class="container plr-30 ptb-20 basis-75 gray-250">
                        Description
                      </div>
                    </div>

                    <div class="flex border-bottom">
                      <div
                        class="container plr-30 ptb-20 basis-25 flex align-center border-right min-w100"
                      >
                        .justify-start
                      </div>
                      <div
                        class="container plr-30 ptb-20 basis-75 flex align-center"
                      >
                        Justify all items to the left
                      </div>
                    </div>

                    <div class="flex border-bottom">
                      <div
                        class="container plr-30 ptb-20 basis-25 flex align-center border-right min-w100"
                      >
                        .justify-center
                      </div>
                      <div
                        class="container plr-30 ptb-20 basis-75 flex align-center"
                      >
                        Justify items to the center of the container
                      </div>
                    </div>

                    <div class="flex border-bottom">
                      <div
                        class="container plr-30 ptb-20 basis-25 flex align-center border-right min-w100"
                      >
                        .justify-end
                      </div>
                      <div
                        class="container plr-30 ptb-20 basis-75 flex align-center"
                      >
                        Justify all items to the right
                      </div>
                    </div>

                    <div class="flex border-bottom">
                      <div
                        class="container plr-30 ptb-20 basis-25 flex align-center border-right min-w100"
                      >
                        .justify-between
                      </div>
                      <div
                        class="container plr-30 ptb-20 basis-75 flex align-center"
                      >
                        Distribute items evenly. First item is on the start
                        line, last item is on the end line.
                      </div>
                    </div>

                    <div class="flex">
                      <div
                        class="container plr-30 ptb-20 basis-25 flex align-center border-right min-w100"
                      >
                        .justify-around
                      </div>
                      <div
                        class="container plr-30 ptb-20 basis-75 flex align-center"
                      >
                        Distribute items evenly with equal space around them
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section id="">
              <div class="content-band">
                <h2>Align items</h2>

                <p>
                  Use these classes to align items on the cross axis.
                </p>

                <p>
                  The cross axis runs perpendicular to the main axis. By default
                  the main axis runs horizontally, so your items will align
                  vertically on the cross axis.
                </p>

                <h3 class="mb-20">CSS</h3>
                <div
                  class="container p-30 gray-250 w800 hover-white hover-shadow"
                >
                  <p class="mt-0">.align-start { align-items: flex-start }</p>
                  <p>.align-center { align-items: center }</p>
                  <p>.align-end { align-items: flex-end }</p>
                </div>

                <div class="mt-30">
                  <h3 class="mb-20">Classes</h3>

                  <div
                    class="flex flex-col border-400 w800 hover-white hover-shadow"
                  >
                    <div class="flex">
                      <div class="container plr-30 ptb-20 basis-25 gray-250">
                        Class
                      </div>
                      <div class="container plr-30 ptb-20 basis-75 gray-250">
                        Description
                      </div>
                    </div>

                    <div class="flex border-bottom">
                      <div
                        class="container plr-30 ptb-20 basis-25 flex align-center border-right min-w100"
                      >
                        .align-start
                      </div>
                      <div
                        class="container plr-30 ptb-20 basis-75 flex align-center"
                      >
                        Align items to the start of the cross axis
                      </div>
                    </div>

                    <div class="flex border-bottom">
                      <div
                        class="container plr-30 ptb-20 basis-25 flex align-center border-right min-w100"
                      >
                        .align-center
                      </div>
                      <div
                        class="container plr-30 ptb-20 basis-75 flex align-center"
                      >
                        Align items to the center of the cross axis
                      </div>
                    </div>

                    <div class="flex border-bottom">
                      <div
                        class="container plr-30 ptb-20 basis-25 flex align-center border-right min-w100"
                      >
                        .align-end
                      </div>
                      <div
                        class="container plr-30 ptb-20 basis-75 flex align-center"
                      >
                        Align items to the end of the cross axis
                      </div>
                    </div>

                    <div class="flex border-bottom">
                      <div
                        class="container plr-30 ptb-20 basis-25 flex align-center border-right min-w100"
                      >
                        .align-baseline
                      </div>
                      <div
                        class="container plr-30 ptb-20 basis-75 flex align-center"
                      >
                        Align items along their baselines
                      </div>
                    </div>

                    <div class="flex">
                      <div
                        class="container plr-30 ptb-20 basis-25 flex align-center border-right min-w100"
                      >
                        .align-stretch
                      </div>
                      <div
                        class="container plr-30 ptb-20 basis-75 flex align-center"
                      >
                        Stretch items from start of cross axis to end of cross
                        axis
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section id="">
              <div class="content-band">
                <h2>Flex basis</h2>
                <p>
                  The flex-basis property specifies the initial length of a
                  flexible item. Use these classes to assign length percentages
                  to flex-items.
                </p>

                <h3 class="mb-20">CSS</h3>
                <div
                  class="container p-30 gray-250 w800 hover-white hover-shadow"
                >
                  <p class="mt-0">.basis-10 { flex: 1 1 10% }</p>
                  <p>.basis-20 { flex:1 1 20% }</p>
                  <p>.basis-25 { flex: 1 1 25% }</p>
                  <p>.basis-30 { flex: 1 1 30% }</p>
                  <p>.basis-33 { flex: 1 1 33% }</p>
                  <p>.basis-40 { flex: 1 1 40% }</p>
                  <p>.basis-50 { flex: 1 1 50% }</p>
                  <p>.basis-60 { flex: 1 1 60% }</p>
                  <p>.basis-70 { flex: 1 1 70% }</p>
                  <p>.basis-75 { flex: 1 1 75% }</p>
                  <p>.basis-80 { flex: 1 1 80% }</p>
                  <p>.basis-90 { flex: 1 1 90% }</p>
                  <p>.basis-100 { flex: 1 1 100% }</p>
                </div>
              </div>
            </section>

            <section id="">
              <div class="content-band">
                <h2>Gap</h2>
                <p>
                  The gap property defines the size of the gap between flexbox
                  items and css grid rows and columns.
                </p>

                <h3 class="mb-20">CSS</h3>
                <div
                  class="container p-30 gray-250 w800 hover-white hover-shadow"
                >
                  <p>.gap-1 { gap: 1px }</p>
                  <p>.gap-2 { gap: 2px }</p>
                  <p>.gap-3 { gap: 3px }</p>
                  <p>.gap-4 { gap: 4px }</p>
                  <p>.gap-5 { gap: 5px }</p>
                  <p>.gap-10 { gap: 10px }</p>
                  <p>.gap-20 { gap: 20px }</p>
                  <p>.gap-30 { gap: 30px }</p>
                  <p>.gap-40 { gap: 40px }</p>
                  <p>.gap-50 { gap: 50px }</p>
                </div>
              </div>
            </section>
          </div>

          <!-- <div class="container w200 p-20">
            <nav class="content-menu">
              <ol class="content-menu-list">
                <li class=""><a href="#ohioBrand">Grid System</a></li>
                <li class=""><a href="#photoSelection">Breakpoints</a></li>
                <li class=""><a href="#photoComposition">Layout</a></li>
                <li class=""><a href="#fileTypes">Spacing Increments</a></li>
                <li class="">
                  <a href="#accessibility">Interactions Spacing</a>
                </li>
                <li class=""><a href="#accessibility">Layering</a></li>
              </ol>
            </nav>
          </div> -->
        </div>
      </div>

      <SubFooter />
    </div>
  </div>
</template>

<script>
import TopLevelLeftNav from "../../components/TopLevelLeftNav";
import SubFooter from "../../components/SubFooter";
export default {
  name: "FlexboxUtilities",
  components: {
    TopLevelLeftNav,
    SubFooter,
  },
};
</script>

<style></style>
